import React from "react"

import "./full-potential.css"

const FullPotentialContent = props => {
  return (
    <>
      <div className="content content-full-potential">
        <h2 className="title title-full-potential">
          <span className="one">Plataforma de </span>
          <span className="two">Máximo Potencial </span>
          <span className="three">de Mars</span>
        </h2>
        <h4 className="title title-full-potential">
          En el mundo que queremos mañana, la sociedad es inclusiva y todas las
          mujeres están alcanzando su máximo potencial.
        </h4>
        <p className="para para-full-potential">
          La evidencia es clara, generar oportunidades para las mujeres crea un
          mundo mejor para todos. Es por eso que estamos realizando cambios
          significativos en nuestros espacios de trabajo, nuestras comunidades
          de abastecimiento y también en nuestra publicidad, para ayudar a
          garantizar que todos tengan la oportunidad de alcanzar su máximo
          potencial.
        </p>
        <a
          href="https://www.mars.com/sustainability-plan/thriving-people/unlocking-opportunities-for-women"
          target="_blank"
          rel="noopener noreferrer"
          className="btn btn-mars-blue rounded-0 btn-full-potential"
          id="learn-more-es"
        >
          Conoce más
        </a>
        <div
          className="transition-control"
          onTransitionEnd={() => props.setScrollControl(true)}
        >
          Transition Control Element
        </div>
      </div>
    </>
  )
}

export default FullPotentialContent
