import React from "react"

import HearOtherWomenVoicesRight from "./hear-other-women-voices-right-mx"
import "./hear-other-women-voices.css"

const HearOtherWomenVoicesLeft = props => (
  <>
    <div className="content content-hear-other-women-voices">
      <h2 className="title title-hear-other-women-voices">
        <span className="one d-md-none">Escucha los mensajes </span>
        <span className="two d-md-none">de otras mujeres </span>
        <span className="one d-none d-md-block">Escucha los </span>
        <span className="two d-none d-md-block">mensajes de </span>
        <span className="three d-none d-md-block">otras mujeres </span>
      </h2>
      <p className="para para-hear-other-women-voices">
        Escucha a algunas de las mujeres de nuestra comunidad que están
        usando sus voces para darle forma a un mejor futuro.
      </p>
      <div className="d-lg-none">
        <HearOtherWomenVoicesRight origin={props.origin} />
      </div>
      <div
        className="transition-control"
        onTransitionEnd={() => {
          props.setScrollControl(true)
          props.setSection("hear-other-women-voices enable-scroll")
        }}
      >
        Transition Control Element
      </div>
    </div>
  </>
)

export default HearOtherWomenVoicesLeft
