import React, { useState, useEffect, useRef } from "react"
import Lottie from "lottie-web"
// import Lottie from "react-lottie"

import "./commitments.css"

import equalPayData from "../../animations/mx/equal-pay.json"
import femaleChangellesData from "../../animations/mx/female-changelles.json"
import financialFreedomData from "../../animations/mx/financial-freedom.json"
import helpCommunitiesData from "../../animations/mx/help-communities.json"
import moreEducationForWomenData from "../../animations/mx/more-education-for-women.json"
import moreWomenInPowerData from "../../animations/mx/more-women-in-power.json"
import noNegativeStereotypesData from "../../animations/mx/no-negative-stereotypes.json"

import LogoCareBlue from "../../images/logo-care-blue.png"

const Commitments = props => {
  const equalPay = useRef(null)
  const femaleChangelles = useRef(null)
  const financialFreedom = useRef(null)
  const helpCommunities = useRef(null)
  const moreEducationForWomen = useRef(null)
  const moreWomenInPower = useRef(null)
  const noNegativeStereotypes = useRef(null)
  useEffect(() => {
    const animEqualPay = Lottie.loadAnimation({
      container: equalPay.current,
      renderer: "svg",
      animationData: equalPayData,
      loop: false,
      autoplay: false,
      rendererSettings: {
        progressiveLoad: true,
      },
    })
    setTimeout(function () {
      animEqualPay.play()
    }, 3000)
    return () => animEqualPay.destroy()
  }, [])

  useEffect(() => {
    const animFemaleChangelles = Lottie.loadAnimation({
      container: femaleChangelles.current,
      renderer: "svg",
      animationData: femaleChangellesData,
      loop: false,
      autoplay: false,
      rendererSettings: {
        progressiveLoad: true,
      },
    })
    setTimeout(function () {
      animFemaleChangelles.play()
    }, 2400)
    return () => animFemaleChangelles.destroy()
  }, [])

  useEffect(() => {
    const animFinancialFreedom = Lottie.loadAnimation({
      container: financialFreedom.current,
      renderer: "svg",
      animationData: financialFreedomData,
      loop: false,
      autoplay: false,
      rendererSettings: {
        progressiveLoad: true,
      },
    })
    setTimeout(function () {
      animFinancialFreedom.play()
    }, 1800)
    return () => animFinancialFreedom.destroy()
  }, [])

  useEffect(() => {
    const animHelpCommunities = Lottie.loadAnimation({
      container: helpCommunities.current,
      renderer: "svg",
      animationData: helpCommunitiesData,
      loop: false,
      autoplay: false,
      rendererSettings: {
        progressiveLoad: true,
      },
    })
    setTimeout(function () {
      animHelpCommunities.play()
    }, 2700)
    return () => animHelpCommunities.destroy()
  }, [])

  useEffect(() => {
    const animMoreEducationForWomen = Lottie.loadAnimation({
      container: moreEducationForWomen.current,
      renderer: "svg",
      animationData: moreEducationForWomenData,
      loop: false,
      autoplay: false,
      rendererSettings: {
        progressiveLoad: true,
      },
    })
    setTimeout(function () {
      animMoreEducationForWomen.play()
    }, 1500)
    return () => animMoreEducationForWomen.destroy()
  }, [])

  useEffect(() => {
    const animMoreWomenInPower = Lottie.loadAnimation({
      container: moreWomenInPower.current,
      renderer: "svg",
      animationData: moreWomenInPowerData,
      loop: false,
      autoplay: false,
      rendererSettings: {
        progressiveLoad: true,
      },
    })
    setTimeout(function () {
      animMoreWomenInPower.play()
    }, 3300)
    return () => animMoreWomenInPower.destroy()
  }, [])

  useEffect(() => {
    const animNoNegativeStereotypes = Lottie.loadAnimation({
      container: noNegativeStereotypes.current,
      renderer: "svg",
      animationData: noNegativeStereotypesData,
      loop: false,
      autoplay: false,
      rendererSettings: {
        progressiveLoad: true,
      },
    })
    setTimeout(function () {
      animNoNegativeStereotypes.play()
    }, 2100)
    return () => animNoNegativeStereotypes.destroy()
  }, [])

  const [stickerInfo, setStickerInfo] = useState("Sample Text")

  const backToCommitments = () => {
    //alert(props.section)
    if (props.section === "commitments sticker-more-education-for-women") {
      props.setSection(
        "commitments commitments-stickers sticker-more-education-for-women-reverse"
      )
    } else if (
      props.section === "commitments section-no-negative-stereotypes"
    ) {
      props.setSection(
        "commitments commitments-stickers section-no-negative-stereotypes-reverse"
      )
    } else if (props.section === "commitments section-financial-freedom") {
      props.setSection(
        "commitments commitments-stickers section-financial-freedom-reverse"
      )
    } else if (props.section === "commitments section-female-changelles") {
      props.setSection(
        "commitments commitments-stickers section-female-changelles-reverse"
      )
    } else if (props.section === "commitments section-equal-pay") {
      props.setSection(
        "commitments commitments-stickers section-equal-pay-reverse"
      )
    } else if (props.section === "commitments section-answer-help") {
      props.setSection(
        "commitments commitments-stickers section-answer-help-reverse"
      )
    } else if (props.section === "commitments section-more-women-in-power") {
      props.setSection(
        "commitments commitments-stickers section-more-women-in-power-reverse"
      )
    }
  }

  return (
    <>
      <div className={`commitments-hldr ${props.setCommitments}`}>
        <h2 className="title title-commitments">
          <span className="one d-none d-lg-block">
            Estas son algunas de las acciones
          </span>
          <span className="two d-none d-lg-block">
            que estamos llevando acabo.
          </span>
          <span className="one d-lg-none">Estas son algunas de </span>
          <span className="two d-lg-none">las acciones que estamos </span>
          <span className="two d-lg-none">llevando acabo.</span>
        </h2>
        <p className="para para-commitments">
          Haz click en los íconos para obtener más información.
        </p>
        <div className="commitments-stickers-hldr">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 position-relative offset-lg-6">
                <h4 className="title title-commitments-stickers">
                  {stickerInfo}
                </h4>
                <figure className="mb-0 logo-care-blue">
                  <img src={LogoCareBlue} alt="" className="img-fluid" />
                </figure>
                {/* <h4 className="title title-commitments-stickers title-prioritise-the-climate">
                  A $10 million investment to empower 50,000 women in cocoa
                  sourcing communities in partnership with CARE.
                </h4>
                <h4 className="title title-commitments-stickers title-answer-help">
                  A $5 million contribution to COVID responses in key countries,
                  with a focus on women, children and migrant workers, in
                  partnership with CARE.
                </h4>
                <h4 className="title title-commitments-stickers title-no-negative-stereotypes">
                  Working to remove gender bias and negative stereotypes in our
                  advertising in partnership with UN Women's Unstereotype
                  Alliance.
                </h4>
                <h4 className="title title-commitments-stickers title-more-women-in-power">
                  Aspiring to reach 100% gender-balanced leadership teams
                  globally.
                </h4>
                <h4 className="title title-commitments-stickers title-equal-pay">
                  Committed to equal pay for male and female Associates across
                  our global businesses.
                </h4>
                <h4 className="title title-commitments-stickers title-financial-freedom">
                  Reaching more than 4,500 women through self-help groups in
                  india.
                </h4>
                <h4 className="title title-commitments-stickers title-female-changelles">
                  Reviewing our advertising annually to identify and reduce
                  gender bias, partnering with the Geena Davis Institute on
                  Gender in Media.
                </h4> */}
              </div>
            </div>
          </div>

          <figure
            className="mb-0 sticker sticker-answer-more-education-for-women sticker-more-education-es"
            id="more-education-for-women"
            ref={moreEducationForWomen}
            onClick={() => {
              props.setSection("commitments sticker-more-education-for-women")
              setStickerInfo(
                "Invertimos $10 millones de dólares para empoderar a 50,000 personas en las comunidades proveedoras de cacao, de la mano con CARE."
              )
            }}
          >
            {/* <Lottie options={moreEducationForWomenOptions} /> */}
          </figure>

          <figure
            className="mb-0 sticker sticker-answer-help sticker-help-communities-es"
            id="help-to-communities"
            ref={helpCommunities}
            onClick={() => {
              props.setSection("commitments section-answer-help")
              setStickerInfo(
                "Contribuimos con $5 millones de dólares, como respuesta al COVID-19 en países clave, con un enfoque a mujeres, niños y trabajadores migrantes, en asociación con CARE."
              )
            }}
          >
            {/* <Lottie options={helpCommunitiesOptions} /> */}
          </figure>

          <figure
            className="mb-0 sticker sticker-answer-no-negative-stereotypes sticker-no-negative-stereotypes-es"
            id="no-negative-stereotypes"
            ref={noNegativeStereotypes}
            onClick={() => {
              props.setSection("commitments section-no-negative-stereotypes")
              setStickerInfo(
                "Trabajamos para eliminar los prejuicios de género y los estereotipos negativos en nuestra publicidad en asociación con la Alianza contra los Esterotipos de ONU Mujeres."
              )
            }}
          >
            {/* <Lottie options={noNegativeStereotypesOptions} /> */}
          </figure>

          <figure
            className="mb-0 sticker sticker-answer-more-women-in-power sticker-more-women-in-power-es"
            id="more-women-in-power"
            ref={moreWomenInPower}
            onClick={() => {
              props.setSection("commitments section-more-women-in-power")
              setStickerInfo(
                "Aspiramos a que el 100% de los equipos de liderazgo a nivel mundial tengan balance de género"
              )
            }}
          >
            {/* <Lottie options={moreWomenInPowerOptions} /> */}
          </figure>

          <figure
            className="mb-0 sticker sticker-answer-equal-pay sticker-equal-pay-es"
            id="equal-pay"
            ref={equalPay}
            onClick={() => {
              props.setSection("commitments section-equal-pay")
              setStickerInfo(
                "Estamos comprometidos con la igualdad de salarios para Asociados independientemente de su género en todo nuestro negocio a nivel global."
              )
            }}
          >
            {/* <Lottie options={equalPayOptions} /> */}
          </figure>

          <figure
            className="mb-0 sticker sticker-answer-financial-freedom sticker-financial-freedom-es"
            id="financial-freedom"
            ref={financialFreedom}
            onClick={() => {
              props.setSection("commitments section-financial-freedom")
              setStickerInfo(
                "Apoyamos a más de 4,500 mujeres en India a través de grupos de auto ayuda y a más de 1,000 mujeres en México gracias a nuestra asociación con Fundación Mitz"
              )
            }}
          >
            {/* <Lottie options={financialFreedomOptions} /> */}
          </figure>

          <figure
            className="mb-0 sticker sticker-answer-female-changelles sticker-reduce-gender-bias-es"
            id="female-changelles"
            ref={femaleChangelles}
            onClick={() => {
              props.setSection("commitments section-female-changelles")
              setStickerInfo(
                `Revisamos nuestra publicidad anualmente para identificar y reducir los prejuicios de género, en asociación con el Instituto "Geena Davis Institute on Gender in Media".`
              )
            }}
          >
            {/* <Lottie options={femaleChangellesOptions} /> */}
          </figure>
        </div>
        {/* <button
          className="btn btn-back-commitments rounded-0"
          onClick={() => props.setSection("commitments commitments-stickers")}
        >
          Back
        </button> */}
        <button
          className="btn btn-back-commitments rounded-0"
          onClick={() => backToCommitments()}
        >
          Atrás
        </button>
        <div
          className="transition-control"
          onTransitionEnd={() => props.setScrollControl(true)}
        >
          Transition Control Element
        </div>
      </div>
    </>
  )
}

export default Commitments
